<template>
  <div v-if="cardData !== undefined" class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
    <vx-card id="card" class="" :title="`${cardTitle} (${sortedData.length})`">
      <p v-if="cardData.length === 0">Não há dados para exibir</p>
      <ul id="List" class="mt-5 flex flex-col pr-5 space-y-4">
        <li v-for="(item, index) in sortedData" :key="index" class="flex justify-between">
          <div id="liText" class="flex items-center">
            <OnlineCircleComponent :online="isOnline(item)" />
            <p class="ml-2 text-lg bold">
              {{ item.name.split(" ", 2)[0] }} {{ item.name.split(" ", 2)[1] }}
            </p>
          </div>
          <div class="flex space-x-4 items-center justify-center">
            {{ isOnline(item) ? "Online" : calcTime(item.lastSeenDate) }}
          </div>
        </li>
      </ul>
    </vx-card>
  </div>
</template>

<script>
import { differenceInHours, differenceInMinutes } from "date-fns";
export default {
  components: {
    OnlineCircleComponent: () =>
      import("@/components/OnlineCircleComponent.vue"),
  },
  props: {
    cardTitle: String,
    cardData: Array,
  },
  methods: {
    isOnline(user) {
      if (user.operatorData && user.operatorData.online) return true;
    },
    calcTime(time) {
      if (!time) return "-";
      let date_time_to = new Date(Date.parse(time));
      if (!new Date(time).getTime() > 0) date_time_to = new Date(time);
      let date_time_from = new Date();
      let finalDate = this.toDate(time);
      let hours = differenceInHours(date_time_from, date_time_to);
      if (hours < 24) {
        if (hours > 0) {
          finalDate = `${hours}h`;
        } else {
          let minutes = differenceInMinutes(date_time_from, date_time_to);
          finalDate = minutes === 0 ? '1min' : `${minutes}min`;
        }
      }

      return finalDate;
    },
    toDate(time) {
      let date_obj = new Date(Date.parse(time));
      let valid = new Date(time).getTime() > 0;
      if (!valid) date_obj = new Date(time);

      let locale = "en-us";
      let monthName = date_obj.toLocaleString(locale, {
        month: "short",
      });
      return date_obj.getDate() + " " + monthName;
    },
  },
  computed: {
    sortedData() {
      const dataArr = this.cardData || [];
      return dataArr.filter(active => active.status === 1).sort((a, b) => {
        if (!a.lastSeenDate) return 1;
        if (!b.lastSeenDate) return -1;
        if (!a.operatorData) return 1;
        if (!b.operatorData) return -1;
        return b.operatorData.online - a.operatorData.online || new Date(b.lastSeenDate) - new Date(a.lastSeenDate);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#card {
  min-height: 100px;
  max-height: 600px;
}

#List {
  overflow-y: auto;
  max-height: 380px;
  box-sizing: border-box;
}

#liText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 20px;
}
</style>
